#sender{
    background-color: blanchedalmond;
  }
  #sender span{
    font-size: 30px;
    margin:10px;
  }
  #sender span a{
    text-decoration: none;
  }
  #sender span a .bi-envelope{
    background-color: blue;
    padding-left:8px ;
    color:white;
    border-radius: 5px;
  }
  #sender span a .bi-globe{
    background-color: red;
    padding-left:8px ;
    color:white;
    border-radius: 5px;
  }
  #sender span a .bi-whatsapp{
    background-color:green;
    padding-left:8px ;
    padding-right: 8px;
    color:white;
    border-radius: 5px;
  }
  
  .header {
     
    padding: 10px 0px 0px 0px;
  }
  
  .invoice_tab{
    margin-top: 20px;
  }
  
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: transparent;
    border-bottom: 4px solid #16B4F3;
    color: #464951;
  }
  
  .nav-pills .nav-link{
    border-radius: 0px;
  }
  
  .invoice_tab .nav-link{
    color:#464951;
    font-weight: 600;
    font-size: 18px;
    width: 100%;
  }
  
  .invoice_preview{
    width: 100%;
  }
  
  .invoice_tab .nav-item{
    width: 50%;
  }
 
  
  ul {
    list-style: none;
    padding-left: 0px;
  }
  
  body {
    height: 100vh;
  }
  
  h5 {
    font-size: 18px;
    font-weight: 600;
  }
  
  h6 {
    font-size: 16px;
    font-weight: 500;
  }
  
  .social_icon {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    border-radius: 4px;
  }
  
.companyInfo h2{
  font-size: 30px;
  font-weight: 700;
}

  .social_icon i {
    color: #fff;
  }
  
  .social_icon:first-child {
    margin-left: 0px;
  }
  
  .social {
    display: flex;
    list-style: none;
    padding-left: 0px;
 
    margin-bottom: 0px;
  }
  .social {
    cursor: pointer;
  }
  
  .header_box_lft {
    background: #ffffff;
    padding: 18px 11px;
  }
  
  .header_box_lft h6 {
    color: #464951;
    font-size: 18px;
    font-weight: 500;
  
  }
  
  .header_box_lft img {
    margin-bottom: 4px;
    height: 40px;
  }
  
  .header {
 
    -webkit-box-shadow: 0px 7px 9px 0px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0px 7px 9px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 7px 9px 0px rgb(0 0 0 / 10%);
margin-bottom: 30px;
padding:0px 270px;
height: 260px;
background: #fff;
background-image: url('/public/images/header-bg.png');
background-position: initial;
background-size: cover;
  }
  
  .header_box {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .container-fluid{
    padding: 0px 0px;
  }
  
  .header_box_ryt img {
    width: 100%;
  }
 
  
  .info_box h5,
  .info_box h6 {
    color: #464951;
  
  
  }
  
  .mid-header {
    padding: 10px 30px 10px 0px;
    margin: 0px;
    display: flex;

    align-items:center;
  }
  .mid_box{
    margin: 0px;
    padding: 0px 250px;
    margin-top: -8.5%;
  }

  .mid-header img{
height: 60px;
 
  }
  
  .accordion-body {
    padding: 0px;
  }
  .accordion-body .info_box .info_list .info_list_item{
    font-size: 15px;
    font-weight: bold;
  }

  .accordion-button{
    padding: 20px 25px;
    text-transform: uppercase;
    font-size: 15px !important;
  }
  .accordion-button[aria-expanded="true"]{
    color: #ffffff !important;
    background: #95c870 !important;
    border: none !important;


  }
  
  .accordion-button:not(.collapsed) {
    background-color: #fff;
    color: #464951;
    box-shadow: none;
    /* border-bottom: none !important; */
  }
  
  
  
  .accordion-button:focus {
    border-color: #fff;
    box-shadow: none;
  }
  
  .accordion-button {
    border-bottom: 2px solid #e7e7e7 !important;
    font-size: 18px;
    color: #464951;
    font-weight: 600;
  }
  
  .owl-theme .owl-dots .owl-dot span {
    background-color: #bad7fd !important
  }
  
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #16B4F3 !important;
  }
  
  .accordion-item {
    border: none;
  }
  
  .accordion-item {
    border-bottom: 0px;
  }
  
  .info_box {
    background-color: #fff;
    /* box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.13); */
    padding: 0px 25px;
    border-radius: 0px;
    margin:0;
    /* border-bottom: 4px solid #60B3D0; */
    height: auto;
    border-bottom: solid 1px #ddd;
    max-height: 250px;
    overflow: auto;
  }
.header_box_lft{
  position: relative;
}
.brand-logo-main{
  align-items: flex-end;
  display: flex;
  justify-content: center;
  padding: 15px 0px 0px 20px;
  border-radius: 10px;
  flex-direction: column;
}

.brand-logo img{
  height: 55px;
}

.brand-logo{

  display: flex;
  justify-content: center;
  align-items: center;
 
  border-radius: 50px;
  font-size: 18px;
    color: #0b0b0b;
    font-weight: 600;
}

.brand-logo-main span img{
  height: 35px;
 
}

  #carouselExampleCaptions{
    box-shadow: 0px 4px 25px rgb(0 0 0 / 13%);
  }
  
  .info_list_item {
    color: #6B6B6B;
    font-size: 14px;
    font-weight: 400;
    margin: 5px 0px 5px 0px;
  }
  embed{
    height: 70vh;
  }
  .mid_box_lft_frst {
    background-color: #fff;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
overflow: hidden;
 margin-bottom: 20px;
  }
  
  .owl-theme .owl-nav {
    display: none;
  }
  
  .banner_slider {
    margin-top: 20px;
  }
  
  .mid_box_lft_frst{
    padding: 0px;
  }
 
  .owl-theme .owl-dots,
  .owl-theme .owl-nav {
    margin-top: 8px;
  }
  
  .mid_box_ryt img {
    width: 100%;
    height: 450px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.13);
    border-radius: 50px;
  }
  
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 8px;
    /* display: none; */
  }
  
  .accordion {
    border: none;
  
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .info_box:hover ::-webkit-scrollbar {
    display: block;
  }
  
  .mid_box_ryt {
    position: relative;
  }
  
  .invoice_preview {
    background-color: #fff;
    box-shadow: 0px -6px 35px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 8px 15px 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header_box_ryt{
    display: flex;
    justify-content: flex-end;
  }
  header_box_ryt h4{
    position:absolute;
    top:115px;
  }
 
  .invoice_preview_lft p {
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 0px;
    background: #fcb746;
    border-radius: 4px;
    font-size: 18px;
    width: inherit;
    font-weight: 700;
    display: inline-block;
    padding: 0px 8px;
    border-radius: 20px;
}
  
  .invoice_preview_lft h3 {
    color: #464951;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  hr{
    margin: 5px 0px;
  }
  
  .invoice_preview_lft h3 span {
    color: #C52626;
    font-size: 15px;
    font-weight: 500;
  }
  
  .preview_icon_list {
    display: flex;
    margin-bottom: 0px;
  }

  li{
    list-style: none;
  }
  
  .preview_icon {
    background-color: #6bac21;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
  }
  
  .preview_icon i {
    color: #fff;
  }
  
  /* *********************************MEDIA CSS START*****************************/
  


  .companyInfo{
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    padding-left: 34px;
  }
 
  
  .header_box_ryt {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .header_box_ryt img{
    height: 80px;
    width: 80px;
  }

.header .container-fluid{
  padding: 0px 0px;
}

.header h4{
  margin-right: 10px;
}
  
  body{
    background: rgb(233,253,233);
    background: linear-gradient(177deg, rgba(233,253,233,1) 0%, rgb(255 255 255) 100%);
  }
  

  .dtl-heading{
    display: block;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
  }

  .dtl-text{
    font-weight: 400;
    font-size: 15px;
  }

  .showdtl-div{

    min-width: 50%;
    display: inline-flex;
    margin: 10px 0px;
    min-height: 48px;
    flex-direction: column;

  }

  .accordion{
    padding:0px 0px;
  }
  
  .SimpleImageSlider-div img{
    width: 100%;
  }

  /*============= Page not found ============ */


 .pageNotFoundLft{
  background: #d3eeee;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 40px 30px;
  border-radius: 30px 0px 0px 30px;
 }

 .main_container{
  height: 100vh;
  padding: 50px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
 }

 .main_container .container{
  height: 100%;
 }

 .pageNotFoundLft h3{
  margin-top: 40px;
 }

 .pageNotFoundLft img{
width: 300px;
display: block;
margin: 0 auto;
 }

 .pageNotFoundInner{
  background-color: #fff;
  border-radius: 40px;
  padding: 10px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  height: 75vh;
 }

 .pageNotFoundBtn{
  background-color: #fff;
  color: #202020;
  border: 2px solid #202020;
  border-radius: 40px;
  text-decoration: none;
  padding: 8px 20px;
  margin-top: 40px;
 }

 .pageNotFoundRyt{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url('../public/images/pageNotFound.png');
  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
 }

 .carousel-control-prev, .carousel-control-next{
  display: none;
 }

 .PNF_bottomBar{
  background-color: #fff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.12);
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 3;
 }

 .PNF_bottomBarList{
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .PNF_bottomBarList li{
  margin: 0px 30px;
  position: relative;
  text-align: center;
 }

 .PNF_bottomBarList li::after{
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: #a7a4a4;
  right: -30px;
  top: 0px;
 }

 .PNF_bottomBarList li a{
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
 }

 .PNF_bottomBarList li:last-child::after, .PNF_bottomBarList li:nth-child(2)::after,
 .PNF_bottomBarList li:nth-child(3)::after{
  display: none;
 }

 .PNGswil_logo  img{
  /* margin-top: -50px; */
  width: 80px;
  
 }
  
  .wrapper {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}
.wrapper h2{
    margin:40px 0;
    font-size:2.5rem;
}
.wrapper h4{
    margin:40px 0 20px;
    font-size:1.2rem;
}
.main-btn{
    padding:15px;
    background-color: #6c63ff;
    color:#fff;
    border:none;
    font-weight:700;
    letter-spacing: 1px;
    border-radius: 6px;
    cursor: pointer;
}

.rpv-core__viewer{
  height: 70vh !important;
  overflow: auto;
}

.rpv-core__page-layer{
  zoom: 1.1;
  margin-top: 20px !important;
}

.carousel-indicators{
  background: #A4CB77;
  bottom: -36px;
  width: 180px;
  border-radius: 30px;
  /* left: 14%; */
  margin: 0 auto;
}
.carousel-indicators [data-bs-target]{
  height: 10px;
  width: 10px;
  border-radius: 50px;
}


.hidden-controls {
  pointer-events: none; /* Disable mouse events on the iframe */
  overflow: hidden; /* Hide any scrollbars that may appear */
  height: 70vh;
  width: 100%;
}


iframe,.invoice_preview_ifram .rpv-core__viewer{
  display: none;
}

.invoice_preview_ifram iframe{
  display: block;
  width: 100%;
  height: 70vh;;
}

.pageNotFoundLft img{
  width: auto !important;
  height: 300px;
}


.pagenotfound-heading{text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

@media (max-width:1699px){
 

  
  .info_box{
    max-height: 170px;
  }
}

@media (max-width:1499px){
 
  .pageNotFoundInner{
    height: auto;
  }


  .accordion-button {
    padding: 12px 25px;
  }

  .pageNotFoundLft h3 {
    margin-top: 15px;
}

.main_container {
 padding: 25px 0px;
}

.pageNotFoundLft img {
  width: 260px;
}
}

@media (max-width:991px){

  .rpv-core__viewer {
    height: 40vh !important;
    overflow: auto;
    overflow: hidden;
}
.mob-view-show-penal{
  margin: 20px 0px;
  margin-bottom: 100px !important;
}

.mob-view-show-penal img{
height: auto;
border-radius: 0px;
}

 .main_container{
  height: auto;
  padding: 20px 0px 125px 0px;
 }

 .pageNotFoundLft h3 {
 font-size: 20px;
}

.PNF_bottomBarList li {
  font-size: 14px;
  margin: 0px 20px;
}

.PNF_bottomBarList li::after {
 
  right: -20px;
}
}

@media (max-width:767px){

  .brand-logo-main span img {
    height: 23px;
}

.invoice_preview{
  left: 0;
}

.invoice_preview ul{
  margin: 5px 0px !important;
}


    .wrapper h2{ 
        font-size:2rem;
    }
    .wrapper h4{ 
        font-size:1rem;
    }

    .pageNotFoundLft{
      border-radius: 30px 30px 30px 30px;
      padding: 40px 15px 60px 15px;
    }

    .PNF_bottomBarList li {
      font-size: 14px;
      margin: 0px 10px;
    }

    .PNF_bottomBarList li::after {
      width: 1px;
      height: 16px;
      right: -10px;
    }
    

    .pageNotFoundRyt{
       padding: 30px 0px;
    }

   

}

@media (max-width:567px){


  .pageNotFoundInner{
    border-radius: 0px;
  background-color: transparent;
  
  }

  .main_container{
    padding: 0px 0px 0px 0px;
  }

  .PNF_bottomBar{
    position: inherit;
  }

  .PNGswil_logo img{
   display: block;
   margin: 0 auto;
  }

 
 .PNF_bottomBarList li:nth-child(2)::after,  .PNF_bottomBarList li:nth-child(3)::after{
  display: block;
 }
}



@media (max-width:499px){
  .PNF_bottomBarList li::after {
    width: 1px;
    height: 30px;
    right: -10px;
    top: 7px;
}

.PNF_bottomBarList li::after{
  display: none !important;
}

.PNGswil_logo img {
 width: 60px;
}

.PNF_bottomBarList{
  flex-wrap: wrap;
}

.PNF_bottomBarList li{
  width: 40%;
  text-align: left;
  margin: 5px 10px;
}
}
 
.Swil-logo-loading{
  width: 120px;
  height: 120px;
  background: #fff;
  border-radius: 100px;
  position: relative;
  padding: 30px;
  -webkit-box-shadow: 0px 0px 34px 0px rgba(0,0,0,0.16);
  -moz-box-shadow: 0px 0px 34px 0px rgba(0,0,0,0.16);
  box-shadow: 0px 0px 34px 0px rgba(0,0,0,0.16);
  display: block;
}
.spin {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  animation: mymove 5s infinite;
  border: 3px solid #7194ef;
  border-top: 1px solid white;
  background: transparent;
  position: absolute;
 left: 0px;
 top: 0px;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}

.industries-icon-box i {
  font-size: 26px;
  color: #346aa7;
}

 

.Swil-logo-loading img{
  width: 100%;
 
}

.Swil-logo-loading{
  background-color: #fff;
  padding: 20px;
  position: absolute;
  left: 47%;
  top: 43%;
}

@keyframes mymove {
  100% {
    transform: rotate(360deg);
  }
}

.full-page-loader{
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff73;
  height: 100%;
  width: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.full-page-loader span{
  
  font-size: 21px;
}



 .mobile-view-tabs{
  display: none;
}


@media (max-width: 999px){

  .tab-content{
    padding: 10px;
    padding-top: 0px;
  }

  .info_box{
    max-height: 100% !important;
    overflow: visible;
  }
 

.mobile-view-tabs{
  display: block;
}
  .header{
    padding: 0px !important;
    overflow: hidden;
    height: inherit;
    background: #fff;
    margin: 0px;
  }

  .header .row{
    flex-direction: column-reverse;
    margin: 0px;
  }


  .brand-logo-main {
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: row;
  
    justify-content: space-between;
}
  .brand-logo-main img{
width: 100%;
height: 40px;
  }


  .companyInfo{
    margin: 0px;
 

    min-width: 100%;
    
    align-items: flex-end;
  }
  
  .companyInfo h2 {
    font-size: 21px;
    font-weight: 700;
    text-align: center;
}


.brand-logo-main span {

  font-size: 20px;
  MARGIN-RIGHT: 15PX;
}

.mid-header {
  justify-content: space-between;
  margin: 0px;
  display: flex;
  align-items: center;
  margin-top: 15PX;
  border-top: solid 1px #dddd;
  width: 100%;
  padding: 11px 20px;
}

.companyInfo{
 min-width: inherit;
}

.companyInfo h2{
  font-size: 20px;
}

.mid-header img {
  height: 45px;}

  .mid_box{
  display: none;
  }


  .mobile-view-tabs ul{
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    border: none;
  }
  .mobile-view-tabs ul li button{
    background-color: #fff !important;
    font-size: 18px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    color: #000 !important;
border-radius: 0px !important;
  }
  .mobile-view-tabs ul li:first-child button{
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
  }
  .mobile-view-tabs ul li:last-child button{
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }


  .mobile-view-tabs ul li button.active{
    background:#468ffc !important;
    color: #fff !important;
    font-weight: 500;
  }

  .invoice_preview{
    min-width: 100%;
    margin: 0px;
    position: fixed;
    bottom: 0px;
    border-radius: 0px;
    z-index: 99;
  }


 .preview_icon_list li:first-child{
  display: none;
 }
 embed{
  margin-bottom: 50px;
}
.info_box{
  padding: 0px 15px
}
.accordion-button{
  padding: 15px 15px
  
}

.mid_box_ryt{
  min-width:100% ;
}

 

}

 


@media (max-width:1600px){
  .mid_box {
    padding: 0px 4%;
    margin-top: -10.5%;
}

.header{
  padding: 0px 6%;
}

 
.rpv-core__page-layer{
  zoom: 0.7;
  margin-top: 20px !important;
}


}



@media (max-width: 480px){
  .invoice_preview_lft h3 span{
   display: block;
      }

      .brand-logo-main img {
        width: 100%;
        height: 32px;
      }
      
      .brand-logo-main span {
        font-size: 16px;
        MARGIN-RIGHT: 15PX;
      }
      .companyInfo h2 {
        font-size: 16px;
    }
      

    .mobile-view-tabs ul li button{
font-size: 15px;
    }

    .mid-header{
      padding: 5px 0px;
      flex-direction: column;
    }

    .companyInfo{
      align-items: center;
      padding-left: 0px !important;
    }

}


 /* .mall-Bill canvas{
 visibility: hidden;
 } 

 .mall-Bill .rpv-core__text-layer {
opacity: inherit !important;
 }

 .mall-Bill .rpv-core__text-layer .rpv-core__text-layer-text{
 

   } */